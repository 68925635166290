.loader {
    border-radius: 50%;
    border-top: 3px solid transparent !important;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
